<template>
    <div class="customer-info">
        <div class="userName">{{ info.userName }}</div>
        <img class="userSex" :src="getSex" width="14px" height="14px"/>
        <div class="userMobile">{{ info.userMobile }}</div>
    </div>
</template>
<script>
import gender_woman from '@/images/gender_woman.png'
import gender_unknown from '@/images/gender_unknown.svg'
import gender_man from '@/images/gender_man.png'
export default {
  props:{
    info:{
      type:Object,
      default: ()=> ({})
    }
  },
  computed: {
    getSex() {
      const obj = {
        1: gender_man,
        2: gender_woman,
        0: gender_unknown,
      }
      return obj[this.info['userSex']] || gender_unknown
    },
  }
}
</script>
<style lang="less" scoped>
.customer-info{
    display: flex;
    align-items: center;

    height: 16px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    font-size: 14px;
    color: #323232;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    .userName,.userSex{
        margin-right: 8px;
    }
    .userMobile{
        position: relative;
        padding-left: 9px;
        &::before{
            position: absolute;
            width: 1px;
            height: 14px;
            background: #D9D9D9;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            content: '';
        }
    }
}
</style>