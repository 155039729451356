<template>
    <div class="test-drive-detail" :style="{'padding-bottom':isShowBtns||showEndDriveBtn?'112px':'16px'}">
        <div class="basic-info">
          <div class="status-name">{{appointmentInfo.tableType | getdectText(appointmentInfo, 'tableType', $store,'2022')}}</div>
          <div class="card" @click="goClueDetail(detail)">
            <div class="title">{{ $t('试驾用户') }}</div>
            <div class="content">
              <CustomerInfo :info="detail"></CustomerInfo>
            </div>
            <phoneIcon :phone="detail.userMobile" :oneself="detail.followUserId === getUserInfo.id" :clueId="detail.id" class="icon"/>
          </div>
          <div class="card">
              <div class="title">{{ $t('试驾时间') }}</div>
              <div class="content">
                  <div v-if="appointmentInfo.typeCode !== '2002004'" class="row">
                      <span class="label">{{ $t('预约试驾时间') }}：</span>
                      <span class="value">{{ $moment(appointmentInfo.appointmentStartTime).format('YYYY-MM-DD HH:mm') }} - {{ $moment(appointmentInfo.appointmentEndTime).format('HH:mm') }}</span>
                  </div>
                  <div v-if="appointmentInfo.status === 9">
                      <span class="label">{{ $t('实际试驾时间') }}：</span>
                      <span  class="value">{{dateFormat(appointmentInfo.driveStartTime,'YYYY-MM-DD HH:mm')}} - {{dateFormat(appointmentInfo.driveEndTime,'HH:mm')}}</span>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="title">{{ $t('试驾车型') }}</div>
              <div v-if="appointmentList.length" class="content">
                <div class="row value">{{ !(appointmentInfo.seriesName||appointmentInfo.seriesName) ? '--' : `${appointmentInfo.seriesName||''} ${appointmentInfo.vehicleName||''}` }}</div>
                <div v-if="appointmentInfo.vinCode || appointmentInfo.carNumber" class="flex-content">
                  <span class="value">{{ appointmentInfo.vinCode }}</span>
                  <span class="carNumber">{{ appointmentInfo.carNumber }}</span>
                </div>
              </div>
          </div>
          <div v-if="['TIMEOUT_CANCEL','ACTIVE_CANCEL'].includes(appointmentInfo.tableType)" class="card">
            <div class="title">{{ $t('取消备注') }}</div>
            <div class="content">
              <div class="row value">{{appointmentInfo.tableType === 'TIMEOUT_CANCEL'?$t('超时取消'):$t('主动取消')}} {{ appointmentInfo.cancelTime }}</div>
              <div class="content">{{ appointmentInfo.cancelRemark }}</div>
            </div>
          </div>
        </div>
        <van-form class="common-block" style="overflow: hidden;" input-align="right" error-message-align="right">
          <template v-if="appointmentInfo.tableType === 'COMPLETED'&&appointmentInfo.driveRecordId">
            <van-field :label="$t('试驾详情')" right-icon="arrow" @click.stop="goReport">
              <template #input>
                {{ $t('查看详情') }}
              </template>
            </van-field>
            <van-field v-if="giftConfig || receiveStatus.statusEnum=== 'RECEIVED'" :label="$t('试驾礼品')" :right-icon="(['UNQUALIFIED', 'NOT_LOTTERY'].includes(receiveStatus.statusEnum)) ? '' : 'arrow'" @click.stop="receiveGift">
              <template #input>
                <span :class="`receiveStatus_${receiveStatus.statusEnum}`" >{{ receiveStatusInfo[receiveStatus.statusEnum]}}</span>
              </template>
            </van-field>
          </template>
          <van-field v-if="appointmentList.length" :label="$t('试驾场景')">
            <template #input>
              <span>{{ appointmentInfo.typeCode | codeFormat }}</span>
              <span v-if="appointmentInfo.typeCode === '2002003'"> - {{ appointmentInfo.activityTitle }}</span>
            </template>
          </van-field>
          <van-field v-if="appointmentList.length" :label="$t('试驾类型')">
            <template #input>
              <span>{{ appointmentInfo.appointmentMethod | codeFormat }}</span>
            </template>
          </van-field>
          <van-field v-if="appointmentList.length && appointmentInfo.typeCode === '2002002'" :label="$t('试驾地点')">
            <template #input>
              <span>{{ appointmentInfo.driveProvinceName }}{{ appointmentInfo.driveCityName }}{{ appointmentInfo.driveDistrictName }}{{ appointmentInfo.driveAddress }}</span>
            </template>
          </van-field>
          <van-field :label="$t('试驾协议')">
            <template #input>
              <span v-if="appointmentInfo?.signStatus === 'COMPLETE'">{{ $t('已签署') }}</span>
              <span v-else style="color: red;">{{ $t('未签署') }}</span>
            </template>
          </van-field>
          <van-field :label="$t('个人信息保护声明')">
            <template #input>
              <span v-if="appointmentInfo?.personalInfoCheckStatus === 1">{{ $t('已授权') }}</span>
              <span v-else style="color: red;">{{ $t('未授权') }}</span>
            </template>
          </van-field>
          <van-field :label="$t('创建时间')" :value="detail.createTime"></van-field>
          <van-field :label="$t(detail.followUserRoleCode === '1014001'? 'DCC':'销售顾问')" :value="detail.followUserName"></van-field>
        </van-form>
        <div v-if="$store.getters.userInfo.type == 1 && (isShowBtns||showEndDriveBtn)" class="common-footer">
          <van-button v-if="appointmentInfo.assignStatus === 0" round class="btn" type="primary" @click="showDistribution = true">{{ $t('分配') }}</van-button>
          <template v-else-if="detail.followUserRoleCode && detail.followUserId">
          <!-- <template v-if="((getUserInfo.authorizeIds||[]).includes(detail?.followUserId) || getUserInfo.id == detail.authorizedEmployeeId) && (getUserInfo.roleLogos||[]).includes(detail.followUserRoleCode)"> -->
            <!-- 非上门试驾的 -->
            <template v-if="appointmentInfo.typeCode !== '2002002'">
              <!-- 待确认 -->
              <template v-if="appointmentInfo.status === 1">
                <van-button round class="btn" type="default" @click="goFollow('1011005')">{{ $t('取消试驾') }}</van-button>
                <van-button round class="btn" type="default" @click="goFollow('1011006')">{{ $t('修改试驾') }}</van-button>
              </template>
              <!-- 待试驾 -->
              <template v-if="appointmentInfo.status === 2">
                <van-button round class="btn" type="default" @click="goFollow('1011005')">{{ $t('取消试驾') }}</van-button>
                <van-button v-if="isShowConfirmBtn"  round class="btn" type="primary" @click="goFollow('','','meet')">{{ $t('确认出席') }}</van-button>
                <van-button round class="btn" type="primary" @click="getNone(appointmentInfo)">{{ $t('开始试驾') }}</van-button>
              </template>
              <!-- 试驾中 -->
              <van-button v-if="appointmentInfo.status === 3" round class="btn" type="primary" @click="testDriveUpdate(appointmentInfo)">{{ $t('结束试驾') }}</van-button>
              <!-- 已完成 && 当前时间小于试驾结束+1 天-->
              <van-button v-if="appointmentInfo.status === 9 && showEndDriveBtn" round class="btn" type="primary" @click="goFollow()">{{ $t('试驾回访') }}</van-button>
            </template>
            <!-- 上门试驾的逻辑 -->
            <template v-if="appointmentInfo.status !== 0 && appointmentInfo.typeCode === '2002002'">
              <!-- <template v-if="appointmentInfo.status !== 0"> -->
                <!-- 店长角色并且预约单状态是待审核 -->
                <template v-if="isShowReviewBtn">
                  <van-button round class="btn" type="primary" @click="openReview">{{ $t('审核') }}</van-button>
                </template>
                <template v-if="appointmentInfo.reviewStatus!=='0'">
                  <van-button v-if="appointmentInfo.status<3" round class="btn" type="default" @click="goFollow('1011005')">{{ $t('取消试驾') }}</van-button>
                  <!-- 审核被驳回时 -->
                  <van-button v-if="appointmentInfo.reviewStatus==='1'" round class="btn" type="primary" @click="goFollow('1011006')">{{ $t('修改试驾') }}</van-button>
                  <!-- 排程 -->
                  <van-button v-if="appointmentInfo.reviewStatus==='2'&&appointmentInfo.status===1" round class="btn" type="primary" @click="goSchedule(detail)">{{ $t('预约排程') }}</van-button>
                  <!-- 确认出席 -->
                  <van-button v-if="appointmentInfo.reviewStatus==='2'&&appointmentInfo.status===2 && isShowConfirmBtn"  round class="btn" type="primary"  @click="goFollow('','','meet')">{{ $t('确认出席') }}</van-button>
                  <!-- 开始试驾 -->
                  <van-button v-if="appointmentInfo.status===2" round class="btn" type="primary" @click="getNone(appointmentInfo)">{{ $t('开始试驾') }}</van-button>
                  <van-button v-if="appointmentInfo.status === 3" round class="btn" type="primary" @click="testDriveUpdate(appointmentInfo)">{{ $t('结束试驾') }}</van-button>
                  <!-- 已完成 && 当前时间小于试驾结束+1 天-->
                  <van-button v-if="appointmentInfo.status === 9 && showEndDriveBtn" round class="btn" type="primary" @click="goFollow()">{{ $t('试驾回访') }}</van-button>  
                </template>
              <!-- </template> -->
            </template>
          <!-- </template> -->
          </template>
        </div>
        <!-- 审核弹窗 -->
        <van-dialog v-model="isShowReview" :title="$t('审核意见')" 
            show-cancel-button 
            :cancel-button-text="$t('驳回')" 
            :confirm-button-text="$t('通过')"
            :close-on-click-overlay="true"
            :before-close="onReview">
            <van-field
              v-model="reviewRemark"
              rows="2"
              autosize
              label=""
              type="textarea"
              maxlength="50"
              :placeholder="$t('请输入审核意见')"
              show-word-limit
            />
          </van-dialog>
        <!-- 排程信息 -->
        <van-popup v-model="showScheduling" style="width:100%; max-width: 500px;height:100%">
          <UpdateVue v-if="showScheduling" :data="appointmentInfo" :showArrow="true" @confirm="confirmScheduling" @close="showScheduling=false"></UpdateVue>
        </van-popup>
        <BusinessFilters ref="filter" v-model="showDistribution" :options="distributionOptions"  :defaultActiveKey="defaultActiveKey"  @submit="distributionFilter"></BusinessFilters>
    </div>
</template>
<script>
import clueServices from '@/services/clueServices'
import { dateFormat } from '@/utils'
import { driveUpdatePost,appointmentReviewPost,getTestDriveAssign } from '@/services/appointment'
import testDriveServices from '@/services/testDrive'
import UpdateVue from '@/modules/appointment/update.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import appointmentMixin from './mixin'
import CustomerInfo from '@/components/card-view/customer-info.vue'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import dayjs from 'dayjs'
import { includes } from 'lodash'
let vm
export default {
  components:{ UpdateVue,phoneIcon,CustomerInfo,BusinessFilters },
  mixins:[appointmentMixin],
  data(){
    return {
      clueId: '',
      appointmentId: '',
      appointmentList: [],
      appointmentInfo: {},
      detail: {},
      isShowReview: false,
      reviewRemark: '',
      showScheduling: false,
      showDistribution: false,
      distributionOptions:[
        {
          name: this.$t('销售顾问'),
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds:'',
          height:'80vh',
          roleCodes: '1014002' // 角色对应的code
        },
      ],
      defaultActiveKey:['saIdList'],
      giftConfig: false, // 是否开启礼品配置
      // receiveStatus: {
      //   statusEnum: 'NOT_SEND-未发送 NOT_RECEIVE-已发送未领取 RECEIVED-已领取 UNQUALIFIED-无领取资格', NOT_LOTTERY= 未中奖
      //   receiveType: '1-线上 2-线下' // 只有已领取的时候才会有receiveType
      // },
      receiveStatus: {
        statusEnum: '',
      }
    }
  },
  computed:{
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    isShowConfirmBtn() {
      const { appointmentStartTime } = this.appointmentInfo
      if (dayjs().isAfter(dayjs(appointmentStartTime))) {
        // 当前时间在预约试驾时间之后，不显示
        return false
      }
      return true
    },
    isShowReviewBtn(){
      const { roleLogos=[], type } = this.getUserInfo
      if (type !== 1) return false
      const { reviewStatus,tableType } = this.appointmentInfo
      const flag = (roleLogos.includes('1014003') || roleLogos.includes('1014004'))&&reviewStatus==='0'&&tableType!=='TIMEOUT_CANCEL'
      console.log('isShowReviewBtn:',flag)
      return flag
    },
    isShowBtns(){
      const { authorizeIds=[], id, roleLogos=[], type } = this.getUserInfo
      if (type !== 1) return false
      const { authorizedEmployeeId, followUserId, followUserRoleCode, appointmentStatusCode } = this.detail
      const { reviewStatus,tableType } = this.appointmentInfo
      const roleLogosMark = roleLogos.some((item) => { return ['1014003', '1014007', '1014004'].includes(item) }) // 店长或者交付店店长或者master
      const flag = ((roleLogos.includes(followUserRoleCode) ) || roleLogosMark)
        && ['CONFIRMED','TIMEOUT_CONFIRMED','TO_BE_CONFIRM','DRIVING'].includes(appointmentStatusCode)&&(!(!roleLogosMark&&reviewStatus==='0'&&tableType!=='TIMEOUT_CANCEL'))
      console.log('isShowBtns:',flag)
      return flag
    },
    showEndDriveBtn() {
      const { driveEndTime, tryDriveVisitStatus } = this.appointmentInfo
      // 当前时间小于试驾结束+1
      const now = dayjs().format('YYYY-MM-DD')
      const driveEnd = dayjs(driveEndTime).format('YYYY-MM-DD')
      const diffDay = dayjs(now).diff(dayjs(driveEnd), 'day')
      return driveEndTime && diffDay >= 1 && !tryDriveVisitStatus
    },
    receiveStatusInfo() {
      return {
        'UNQUALIFIED': this.$t('无领取资格'),
        'NOT_SEND': this.$t('未发送'),
        'NOT_RECEIVE': this.$t('已发未领'),
        'RECEIVED': this.$t('已领取'),
        'NOT_LOTTERY': this.$t('未中奖'),
      }
    },
  },
  watch: {
    appointmentInfo: {
      handler(val) {
        const { tableType, driveRecordId, id:appointId, customerMobile:phone, seriesCode } = val
        if (tableType === 'COMPLETED' && driveRecordId) {
          this.giftJudge({ appointId,phone,seriesCode })
          this.getGiftConfig()
        }
      },
      immediate: true,
    },
  },
  mounted(){
    const { id,clueId } = this.$route.query
    this.appointmentId = id
    this.clueId = clueId
    this.getClueDetail()
  },
  methods:{
    dateFormat,
    async giftJudge(params) {
      const res = await testDriveServices.reveiveJudge(params)
      this.receiveStatus = res
    },
    async getGiftConfig() {
      const res = await testDriveServices.giftConfigEntrance({ appointId: this.appointmentId })
      this.giftConfig = res
    },
    getClueDetail() {
      clueServices.getClueDetail({ id: this.clueId, appointmentId: this.appointmentId }).then(res=>{
        this.appointmentList = res.appointments || []
        this.appointmentInfo = res.appointments[0] || {}
        this.detail = res
      })
    },
    openReview(){
      this.reviewRemark = ''
      this.isShowReview = true
    },
    // 审核驳回 1：驳回、2：通过
    async onReview(action, done){
      if (action==='overlay')done()
      if (action==='cancel'&&!this.reviewRemark.trim()) {
        this.$toast(this.$t('请输入审核意见'))
        done(false)
        return
      }
      if (['confirm','cancel'].indexOf(action)>-1){
        const params = {
          reviewStatus: action==='cancel'?1:2,
          reviewRemark: this.reviewRemark,
          id: this.appointmentId
        }
        const res = await appointmentReviewPost(params)
        done()
        window.location.reload()
      }
    },
    goFollow(followResult='', followMode='', selfStatus='') {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id: this.clueId,
          appointmentId: this.appointmentId,
          followResult, // '1011005', // 1011005取消预约  1011006修改预约
          followMode, // 1010003 店内接待
          selfStatus,
        }
      })
    },
    // 跳转至详情
    goClueDetail({ id }) {
      if (this.appointmentInfo.leadTransferFlag===1){
        return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
      }
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    },
    // 试驾报告
    goReport() {
      if (this.appointmentInfo.tableType != 'COMPLETED') {
        this.$toast(this.$t('报告生成中，请稍后查看'))
        return false
      }
      this.$router.push({
        path: '/test-drive-record',
        query: {
          driveRecordId: this.appointmentInfo.driveRecordId,
          appointmentId: this.appointmentId
        }
      })
      // this.$router.push({
      //   path: '/test-drive-report',
      //   query: {
      //     appointmentId: this.appointmentId,
      //   }
      // })
    },
    // 分配
    distributionFilter(params){
      const { saIdList } = params
      if (saIdList.length){
        const { name , value } = saIdList[0]
        getTestDriveAssign({
          empId:value,
          empName:name,
          ids:[this.appointmentId],
          roleLogo:'1014002'
        }).then(res=>{
          this.getClueDetail()
          this.$toast(this.$t('分配成功'))
        })
      } else {
        this.$toast(this.$t('请选择分配人员'))
      }
    },
    // 上门试驾 排程
    goSchedule(clueDetail){
      // const item = clueDetail.appointments[0]
      // this.$set(this,'appointmentInfo',item)
      this.showScheduling = true
    },
    //  确认排程
    async confirmScheduling(data) {
      data.appointmentStartTime = dateFormat(data.appointmentStartTime, 'YYYY-MM-DD HH:mm:ss')
      data.appointmentEndTime = dateFormat(data.appointmentEndTime, 'YYYY-MM-DD HH:mm:ss')
      data.modelCode = data.vehicleCode
      // 直接排程
      data.id = this.appointmentInfo.id
      await driveUpdatePost(data)
      this.showScheduling = false
      window.location.reload()
    },
    // 领取礼品
    receiveGift() {
      // 已发未领 支持重复发送 可以跳转
      const { statusEnum, receiveType } = this.receiveStatus
      if (['UNQUALIFIED', 'NOT_LOTTERY'].includes(statusEnum)) return false
      const { customerMobile, seriesCode, id } = this.appointmentInfo
      this.$router.push({
        path: '/testDriveGift',
        query: {
          id,
          customerMobile,
          seriesCode,
          statusEnum,
          receiveType,
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.test-drive-detail{
  padding: 16px;
  padding-bottom: 96px;
  .status-name{
    height: 24px;
    font-family: HYQiHei, HYQiHei;
    font-weight: normal;
    font-size: 16px;
    color: #0D171A;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-bottom: 12px;
  }

  .basic-info{
    background-color: #fff;
    padding: 12px;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .card{
    position: relative;
      margin-bottom: 12px;
      background: #F4F8FE;
      padding: 8px 12px;
      border-radius: 4px;
      &:last-child{
        margin-bottom: 0;
      }
      .title{
        height: 20px;
        font-family: HYQiHei, HYQiHei;
        font-weight: normal;
        font-size: 14px;
        color: #0D171A;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 8px;
      }
      .content{
        background: #F4F8FE;
        border-radius: 8px;
        font-size: 14px;
        .row{
          margin-bottom: 8px;
        }
        .label{
          height: 16px;
          font-family: HYQiHei, HYQiHei;
          font-weight: normal;
          font-size: 13px;
          color: rgba(13,23,26,0.45);
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .value{
          height: 16px;
          font-family: HYQiHei, HYQiHei;
          font-weight: normal;
          font-size: 13px;
          color: #0D171A;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        .carNumber{
          height: 20px;
          padding: 2px 4px;
          background: #3476FE;
          border-radius: 2px 2px 2px 2px;

          font-family: HYQiHei, HYQiHei;
          font-weight: normal;
          font-size: 12px;
          color: #FFFFFF;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          user-select: none;
        }
        .flex-content{
          display: flex;
          justify-content: space-between;
        }
      }
      .icon{
        position: absolute;
        top: 8px;
        right: 8px;
        width: 32px;
        height: 32px;
      }
  }
  .receiveStatus_UNQUALIFIED{
    color: rgba(13,23,26,0.45);
  }
  // .receiveStatus_NOT_RECEIVE{
  //   color: @black
  // }
  .receiveStatus_NOT_SEND, .receiveStatus_RECEIVED, .receiveStatus_NOT_RECEIVE{
    color: @yellow-text
  }
}
// .common-footer{
//   height: auto;
//   .btn{
//     margin-bottom: 20px;
//     margin-top:20px;
//   }
// }
</style>
